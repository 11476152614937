import { Box, Divider, Typography, useTheme, useMediaQuery } from "@mui/material";
import SectionText from "components/SectionText";
import SectionContainer from "components/SectionContainer";
import SectionImage from "components/SectionImage";

const IntroWidget = () => {
    const { palette } = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
    const isNonMaxWidth = useMediaQuery("(max-width: 1700px)");
    const defaultBackground = palette.background.default;
    const textColor = palette.neutral.main;
    const TEXT_SPACING = "2rem"

    return (
        <SectionContainer
            alignItems="center"
            bgColor={defaultBackground}
            gap="3rem"
        >
            <Box
                flexBasis={isNonMobileScreens ? "50%" : undefined}
                display="flex"
                flexDirection="column"
                gap={TEXT_SPACING}
            >
                <Typography variant="h2" fontWeight="bold" textAlign="center" color={textColor}>
                    I help companies leverage cutting-edge technology and practices to resolve business challenges and distinguish them from their competitors
                </Typography>
                <Divider sx={{
                    alignSelf: "center",
                    width: "75%",
                    bgcolor: '#F8B669',
                    borderBottomWidth: 6,
                    margin: '1rem 0'
                }} />
                <SectionText
                    text="As someone with extensive business and technical experience, I know how to leverage cutting-edge technology and best practices to help your company overcome its toughest challenges. Whether you're facing operational inefficiencies, fierce competition, or changing market conditions, I have the skills and expertise to help you succeed."
                />
                <SectionText
                    text="What sets me apart is my ability to think strategically and execute effectively, using a data-driven approach to ensure that every decision is based on sound analysis and insight. By partnering with me, you'll gain a valuable ally who can help you stay ahead of the curve, build a stronger brand, and achieve sustainable growth."
                />
                <SectionText
                    text="So whether you need help with software development, business strategy, or anything in between, I'm here to help. Let's work together to take your company to the next level!"
                />
            </Box>
            <SectionImage 
                flexBasisValue={isNonMobileScreens ? "50%" : undefined}
                heightValue={isNonMaxWidth ? undefined : "50vh"}
                imgWidth={isNonMaxWidth ? "85%" : isNonMobileScreens ? "60%" : "100%"}
                imgAlt="Sergio Gomez Jr."
                imgSrc="../assets/portrait.jpeg"
            />
        </SectionContainer>
    );
};

export default IntroWidget;
