import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Form from "./Form";

const CallToActionPage = () => {
    const { palette } = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
    const textColor = palette.neutral.main;

    return (
        <Box
            width="100%"
            padding="2rem 6%"
            display={isNonMobileScreens ? "flex" : "block"}
            backgroundColor={palette.neutral.light}
            justifyContent="space-evenly"
            alignItems="center"
        >
            <Box
                flexBasis={isNonMobileScreens ? "40%" : undefined}
            >
                <Typography variant="h1" fontWeight="bold" textAlign="center" color={textColor}>
                    Let's win together.
                </Typography>
            </Box>
            <Box
                flexBasis={isNonMobileScreens ? "60%" : undefined}
                textAlign="center"
                padding="2rem"
                m="2rem auto"
                borderRadius="1.5rem"
                backgroundColor={palette.background.default}
                boxShadow="rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"
            >
                <Form />
            </Box>
        </Box>
    );
}

export default CallToActionPage;
