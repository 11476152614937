import { Box } from "@mui/material";
import Navbar from "scenes/navbar";
import VideoWidget from "scenes/widgets/VideoWidget";
import IntroWidget from "scenes/widgets/IntroWidget";
import CallToActionPage from "scenes/callToActionPage";
import FooterWidget from "scenes/widgets/FooterWidget";

const HomePage = () => {
    return (
        <Box>
            <Navbar />
            <VideoWidget />
            <IntroWidget />
            <CallToActionPage />
            <FooterWidget />
        </Box>
    );
};

export default HomePage;
