export const colorTokens = {
    blue: {
        50: "#eceff1",
        100: "#cfd8dc",
        200: "#b0bec5",
        300: "#90a4ae",
        400: "#78909c",
        500: "#607d8b",
        600: "#344A5F",
        700: "#455a64",
        800: "#37474f",
        900: "#263238",
    },
    primary: {
        0: "#ffffff",
        50: "#f9f1f0",
        100: "#ebdcdb",
        200: "#dcc5c5",
        300: "#ccacac",
        400: "#be9696",
        500: "#b18181",
        600: "#a27677",
        700: "#892b30",
        800: "#7e5c5f",
        900: "#6c4e52",
    },
    misc: {
        gold: "#F8B669",
        green: "#679889"
    }
};

// Primary = primary 50 - #   - Ivory
// Secondary = blue 600 -  #546e7a - grey/blue
export const themeSettings = () => {
    return {
        palette: {
            primary: {
                dark: colorTokens.primary[700],
                main: colorTokens.primary[50],
                light: colorTokens.primary[500],
            },
            neutral: {
                dark: colorTokens.blue[900],
                main: colorTokens.blue[600],
                medium: colorTokens.blue[400],
                light: colorTokens.blue[200],
            },
            background: {
                default: colorTokens.primary[50],
                alt: colorTokens.primary[0],
            },
            misc: {
                gold: colorTokens.misc.gold,
                green: colorTokens.misc.green
            }
        },
        typography: {
            fontFamily: ["Lato", "Mrs Saint Delafield", "sans-serif"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ["Lato", "sans-serif"].join(","),
                fontSize: 40,
            },
            h2: {
                fontFamily: ["Lato", "sans-serif"].join(","),
                fontSize: 32,
            },
            h3: {
                fontFamily: ["Lato", "sans-serif"].join(","),
                fontSize: 24,
            },
            h4: {
                fontFamily: ["Lato", "sans-serif"].join(","),
                fontSize: 20,
            },
            h5: {
                fontFamily: ["Lato", "sans-serif"].join(","),
                fontSize: 16,
            },
            h6: {
                fontFamily: ["Lato", "sans-serif"].join(","),
                fontSize: 14,
            },
            name: {
                fontFamily: ["Mrs Saint Delafield", "sans-serif"].join(",")
            }
        }
    };
};
