import { Box } from "@mui/material";
import Navbar from "scenes/navbar";
import AboutWidget from "scenes/widgets/AboutWidget";
import AboutMeWidget from "scenes/widgets/AboutMeWidget";
import EduExpWidget from "scenes/widgets/EduExpWidget";
import FooterWidget from "scenes/widgets/FooterWidget";
import CallToActionPage from "scenes/callToActionPage";
import ExpertiseWidget from "scenes/widgets/ExpertiseWidget";

const AboutPage = () => {
    return (
        <Box>
            <Navbar />
            <AboutWidget />
            <AboutMeWidget />
            <EduExpWidget />
            <ExpertiseWidget />
            <CallToActionPage />
            <FooterWidget />
        </Box>
    );
};

export default AboutPage;
