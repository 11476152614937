import { Box } from "@mui/material";
import { styled } from "@mui/system";

/**
 * Section Container - Custom Box Component
 * @param {string} bgColor - Background color of the container
 */
const SectionContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'bgColor',
    name: 'SectionContainer',
    slot: 'Root'
    })
    (({ theme, bgColor }) => ({
            width: "100%",
            display: "flex",
            backgroundColor: bgColor,
            padding: "5rem 6%",

            [theme.breakpoints.down("md")]: {
                display: "flex",
                flexDirection: "column",
                padding: "2rem 6%"
            }
        })
    );

export default SectionContainer;
