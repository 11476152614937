import {
    Typography,
    useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";

const Navbar = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const defaultBackground = theme.palette.background.default;
    const neutralMain = theme.palette.neutral.main;
    const primaryDark = theme.palette.primary.dark;

    return (
        <FlexBetween padding="1rem 6%" backgroundColor={defaultBackground}>
            <FlexBetween gap="1.75rem">
                <Typography
                    letterSpacing="0.15rem"
                    fontWeight="bold"
                    fontSize="clamp(1rem, 1.5rem, 2rem)"
                    color={neutralMain}
                    onClick={() => navigate("/")}
                    sx={{
                        "&:hover": {
                            color: primaryDark,
                            cursor: "pointer"
                        }
                    }}
                >
                    Home
                </Typography>
            </FlexBetween>
            <FlexBetween gap="1.75rem">
                <Typography
                    letterSpacing="0.15rem"
                    fontWeight="bold"
                    fontSize="clamp(1rem, 1.5rem, 2rem)"
                    color={neutralMain}
                    onClick={() => navigate("/about")}
                    sx={{
                        "&:hover": {
                            color: primaryDark,
                            cursor: "pointer"
                        }
                    }}
                >
                    About
                </Typography>
            </FlexBetween>
        </FlexBetween>
    );
};

export default Navbar;
