import { Box, useTheme, useMediaQuery } from "@mui/material";
import {
    IntegrationInstructions,
    ManageSearch,
    Webhook,
    AutoGraph,
    ModelTraining,
    RocketLaunch,
    WorkspacePremium
} from '@mui/icons-material';
import SectionContainer from "components/SectionContainer";
import SectionTitle from "components/SectionTitle";
import SectionText from "components/SectionText";
import FlexColumn from "components/FlexColumn";

const ExpertiseWidget = () => {
    const { palette } = useTheme();
    const isNonMaxWidth = useMediaQuery("(max-width: 1700px)");
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

    return (
        <SectionContainer
            flexDirection="column"
            bgColor={palette.background.alt}
        >
            <Box
                flexBasis={isNonMobileScreens ? "40%" : undefined}
                padding={isNonMobileScreens ? "1.5rem 0" : "3rem 0"}
            >
                <SectionTitle title="Expertise" color={palette.neutral.main} />
            </Box>
            <Box
                display="flex"
                flexDirection={isNonMobileScreens ? "row" : "column"}
                padding={isNonMobileScreens ? "1.5rem 0" : "3rem 0"}
                gap="3rem"
                justifyContent="space-evenly"
            >
                <FlexColumn alignItems="center">
                    <IntegrationInstructions sx={{ color: '#F8B669', fontSize: "50px" }} />
                    <SectionText text="Software Engineering" />
                </FlexColumn>
                <FlexColumn alignItems="center">
                    <ManageSearch sx={{ color: '#F8B669', fontSize: "50px" }} />
                    <SectionText text="Business Analysis" />
                </FlexColumn>
                <FlexColumn alignItems="center" >
                    <Webhook sx={{ color: '#F8B669', fontSize: "50px" }} />
                    <SectionText text="AI Technology Integration" />
                </FlexColumn>
                <FlexColumn alignItems="center" >
                    <AutoGraph sx={{ color: '#F8B669', fontSize: "50px" }} />
                    <SectionText text="Growth Marketing" />
                </FlexColumn>
            </Box>
            <Box
                width={isNonMaxWidth ? "100%" : "65%"}
                borderRadius="10px"
                boxShadow="rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"
                padding={isNonMobileScreens ? "1.5rem 0" : "3rem 0"}
                marginTop={isNonMobileScreens ? "5rem" : undefined}
                backgroundColor={palette.misc.green}
                display="flex"
                alignSelf="center"
                flexDirection={isNonMobileScreens ? "row" : "column"}
                gap="3rem"
            >
                <Box
                    flexBasis={isNonMobileScreens ? "50%" : undefined}
                    padding="0 1.5rem"
                >
                    <SectionTitle title="Building customer centric products" dividerColor={palette.background.alt} />
                    <FlexColumn
                        paddingTop="2rem"
                        gap="3rem"
                    >
                        <Box
                            display="flex"
                            flexDirection={isNonMobileScreens ? "row" : "column"}
                            gap="2rem"
                        >
                            <WorkspacePremium sx={{ color: '#F8B669', fontSize: "50px" }} />
                            <Box>
                                <SectionText text="Product Discovery" textColor={palette.misc.gold} isSectionHeader={true} />
                                <SectionText
                                    textColor={palette.background.alt}
                                    text="Assessing value, usability, feasibility, and business viability risks."
                                />
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection={isNonMobileScreens ? "row" : "column"}
                            gap="2rem"
                        >
                            <ModelTraining sx={{ color: '#F8B669', fontSize: "50px" }} />
                            <Box>
                                <SectionText
                                    text="Product Development" textColor={palette.misc.gold} isSectionHeader={true} />
                                <SectionText
                                    text="Utilizing best engineering practices to build robust and scalable implementations that will provide consistently reliable value."
                                    textColor={palette.background.alt}
                                />
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection={isNonMobileScreens ? "row" : "column"}
                            gap="2rem"
                        >
                            <RocketLaunch sx={{ color: '#F8B669', fontSize: "50px" }} />
                            <Box>
                                <SectionText
                                    text="Product Delivery" textColor={palette.misc.gold} isSectionHeader={true} />
                                <SectionText
                                    text="Integrating your tailored solution into your existing ecosystem to begin realizing value."
                                    textColor={palette.background.alt}
                                />
                            </Box>
                        </Box>
                    </FlexColumn>
                </Box>
                <Box
                    flexBasis={isNonMobileScreens ? "50%" : undefined}
                    padding="0 1.5rem"
                    position="relative"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <img
                        width={isNonMaxWidth ? "100%" : isNonMobileScreens ? "80%" : "100%"}
                        height="100%"
                        style={{
                            objectFit: "cover",
                            borderRadius: "5%"
                        }}
                        alt="Office"
                        src="../assets/expertisebg.jpeg"
                    />
                    <img
                        width={isNonMaxWidth ? "85%" : isNonMobileScreens ? "70%" : "100%"}
                        height="auto"
                        style={{
                            position: "absolute",
                            borderRadius: "5%"
                        }}
                        alt="Labtop"
                        src="../assets/macbook.png"
                    />
                </Box>
            </Box>
        </SectionContainer>
    );
};

export default ExpertiseWidget;
