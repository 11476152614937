import { useState } from "react";
import {
    Box,
    Button,
    TextField,
    useMediaQuery,
    Typography,
    useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { Formik } from "formik";
import * as yup from "yup";

/* INPUT VALIDATION */
const inquirySchema = yup.object().shape({
    name: yup.string().required("required"),
    email: yup.string().email("Invalid email").required("required"),
    message: yup.string().required("required")
});

const initialValuesInquiry = {
    name: "",
    email: "",
    message: ""
};

/**
 * Form Component
 * @param {string} name 
 * @param {string} email
 * @param {string} message
 * 
 * Checks:
 * - No duplicate emails
 * 
 * Flow:
 * - User submits
 *      - if good -> render success component 
 *      - if no -> save payload and reload form with payload values as the initial values
 */
const Form = () => {
    const [inquiryDetails, setInquiryDetails] = useState(null);
    const { palette } = useTheme();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const ERROR_MESSAGE = "No duplicate emails per inquiry";

    let showDuplicateEmailMessage = false;

    const handleSubmitInquiry = async (values, onSubmitProps) => {
        const inquirynResponse = await fetch("https://sgomez-website-api.onrender.com/inquiry", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values),
        });
        const inquiryResult = await inquirynResponse.json();

        if (!inquiryResult.keyValue) {
            onSubmitProps.resetForm();
            setInquiryDetails(inquiryResult);
        }

        showDuplicateEmailMessage = !showDuplicateEmailMessage;
    }

    const StyledTextField = styled(TextField)({
        gridColumn: "span 4",
        '& label.Mui-focused': {
            color: palette.neutral.main,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: palette.neutral.main,
            },
        }
    });

    return (
        <>
            {inquiryDetails ? (
                <>
                    <Box>
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            color={palette.neutral.main}
                        >
                            Looking forward to winning with you {inquiryDetails.name}! I'll be in contact shortly.
                        </Typography>
                    </Box>
                </>
            ) : (
                <Formik
                    onSubmit={handleSubmitInquiry}
                    initialValues={initialValuesInquiry}
                    validationSchema={inquirySchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(4, minmax(0,1fr))"
                                sx={{
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                    "& .MuiOutlinedInputInput": { color: "red" }
                                }}
                            >
                                <StyledTextField
                                    label="Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    name="name"
                                    error={Boolean(touched.name) && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                                <StyledTextField
                                    label="Email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    name="email"
                                    error={showDuplicateEmailMessage || (Boolean(touched.message) && Boolean(errors.message))}
                                    helperText={showDuplicateEmailMessage ? ERROR_MESSAGE : touched.message && errors.message}
                                />
                                <StyledTextField
                                    label="Message"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.message}
                                    name="message"
                                    error={Boolean(touched.message) && Boolean(errors.message)}
                                    helperText={touched.message && errors.message}
                                />
                            </Box>

                            {/* BUTTON */}
                            <Box>
                                <Button
                                    fullWidth={false}
                                    type="submit"
                                    sx={{
                                        m: "2rem 0",
                                        p: "1rem",
                                        backgroundColor: palette.primary.dark,
                                        color: palette.background.alt,
                                        fontWeight: "bold",
                                        "&:hover": {
                                            color: palette.neutral.dark,
                                            backgroundColor: palette.primary.dark,
                                        },
                                        width: "50%"
                                    }}
                                >
                                    SEND
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            )}
        </>
    );
}

export default Form;
