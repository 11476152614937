import { Box, Divider, Typography } from "@mui/material";
const GOLD_COLOR = '#F8B669'

/**
 * Section Title Component
 * @param {string} title - Title text
 * @param {string} [color = = theme.palette.misc.gold] - Title color
 * @param {string} [dividerColor = heme.palette.misc.gold] - Divider color
 */
const SectionTitle = ({ title, color = GOLD_COLOR, dividerColor = GOLD_COLOR }) => {
    return (
        <Box
            display="inline-block"
        >
            <Typography variant="h2" fontWeight="bold" color={color}>
                {title}
            </Typography>
            <Divider sx={{
                bgcolor: dividerColor,
                borderBottomWidth: 6,
                margin: '1rem 0'
            }} />
        </Box>
    );
};

export default SectionTitle;
