import { Typography } from "@mui/material";
const BLUE_COLOR = '#344A5F'

/**
 * Section Text Component
 * @param {string} text - Text to display
 * @param {string} [textColor = theme.palette.neutral.main] - Text color
 * @param {boolean} [isSectionHeader = false] - Determines component variant
 */
const SectionText = ({ text, textColor = BLUE_COLOR, isSectionHeader = false }) => {
    return (
        <Typography color={textColor} variant={isSectionHeader ? "h3" : "h4"} fontWeight="bold" >
            {text}
        </Typography>
    );
};

export default SectionText;
