import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import SectionContainer from "components/SectionContainer";
import SectionTitle from "components/SectionTitle";

const EduExpWidget = () => {
    const { palette } = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

    return (
        <SectionContainer
            bgColor={palette.background.default}
        >
            <Box
                flexBasis={isNonMobileScreens ? "40%" : undefined}
                padding={isNonMobileScreens ? "1.5rem 0" : "3rem 0"}
            >
                <SectionTitle title="Education" color={palette.neutral.main} />
                <Box
                    paddingTop="2rem"
                    display="flex"
                    flexDirection="column"
                    gap="2rem"
                >
                    <Typography variant="h4" fontWeight="bold" color={palette.neutral.main}>
                        Rensselaer Polytechnic Institute (RPI)
                    </Typography>
                    <Typography variant="h4" fontWeight="bold" color={palette.neutral.main}>
                        Bachelor of Science: Computer Science
                    </Typography>
                    <Typography variant="h4" fontWeight="bold" color={palette.neutral.main}>
                        Concentration Area: Artificial Intelligence and Data
                    </Typography>
                    <Typography variant="h4" fontWeight="bold" color={palette.neutral.main}>
                        Graduated: May 2019
                    </Typography>
                </Box>
            </Box>
            <Box
                flexBasis={isNonMobileScreens ? "60%" : undefined}
                backgroundColor={palette.neutral.main}
                borderRadius="10px"
                boxShadow="rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"
                padding={isNonMobileScreens ? "1.5rem 0" : "3rem 0"}
            >
                <Box
                    paddingLeft="1.5rem"
                >
                    <SectionTitle title="Experience" color={palette.background.alt} />
                </Box>
                <Box
                    paddingTop="1rem"
                >
                    <Timeline position="alternate">
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    GalaTech Digital Solutions, LLC
                                </Typography>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Founder
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot sx={{ bgcolor: '#F8B669', borderWidth: '8px' }} />
                                <TimelineConnector sx={{ bgcolor: '#F8B669' }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    San Francisco, CA
                                </Typography>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    2017 - Present
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Mountain View, CA
                                </Typography>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    2019 - Present
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot sx={{ bgcolor: '#F8B669', borderWidth: '8px' }} />
                                <TimelineConnector sx={{ bgcolor: '#F8B669' }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Atlassian
                                </Typography>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Software Engineer
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Ripplematch
                                </Typography>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Growth Marketing Intern
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot sx={{ bgcolor: '#F8B669', borderWidth: '8px' }} />
                                <TimelineConnector sx={{ bgcolor: '#F8B669' }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Troy, NY
                                </Typography>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    2018 - 2019
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Mountain View, CA
                                </Typography>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    2018 - 2019
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot sx={{ bgcolor: '#F8B669', borderWidth: '8px' }} />
                                <TimelineConnector sx={{ bgcolor: '#F8B669' }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Atlassian
                                </Typography>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Software Engineering Intern
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Vaco
                                </Typography>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Google Student Innovator
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot sx={{ bgcolor: '#F8B669', borderWidth: '8px' }} />
                                <TimelineConnector sx={{ bgcolor: '#F8B669' }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Troy, NY
                                </Typography>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    2018 - 2019
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Charlotte, NC
                                </Typography>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    2016 - 2017
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot sx={{ bgcolor: '#F8B669', borderWidth: '8px' }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Pepper1 Inc.
                                </Typography>
                                <Typography variant="h5" align="center" fontWeight="bold" color={palette.background.alt}>
                                    Expansion Analyst Intern
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                </Box>
            </Box>
        </SectionContainer>
    );
};

export default EduExpWidget;
