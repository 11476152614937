import { Box, useTheme, useMediaQuery } from "@mui/material";
import SectionContainer from "components/SectionContainer";
import SectionTitle from "components/SectionTitle";
import SectionText from "components/SectionText";
import SectionImage from "components/SectionImage";
import FlexColumn from "components/FlexColumn";

const AboutMeWidget = () => {
    const { palette } = useTheme();
    const isNonMaxWidth = useMediaQuery("(max-width: 1700px)");
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

    return (
        <SectionContainer
            bgColor={palette.background.alt}
            gap="3rem"
        >
            <SectionImage
                flexBasisValue={isNonMobileScreens ? "50%" : undefined}
                heightValue={isNonMaxWidth ? undefined : "50vh"}
                imgWidth={isNonMaxWidth ? "85%" : isNonMobileScreens ? "60%" : "100%"}
                imgAlt="Sergio Gomez Jr."
                imgSrc="../assets/aboutme.jpeg"
            />
            <Box
                flexBasis={isNonMobileScreens ? "50%" : undefined}
                padding={isNonMobileScreens ? "1.5rem 0" : "3rem 0"}
            >
                <SectionTitle title="Who Am I" color={palette.neutral.main} />
                <FlexColumn
                    paddingTop="3rem"
                    gap="2rem"
                >
                    <SectionText
                        text="Hi there!"
                    />
                    <SectionText
                        text="I'm a seasoned entrepreneur and software engineer with a passion for helping my clients harness cutting-edge technologies to gain an edge over their competition. My specialty is creating custom solutions that leverage the power of artificial intelligence and data analysis to drive growth and improve efficiency."
                    />
                    <SectionText
                        text="With experience ranging from operating my digital consulting firm to engineering parallel graph algorithms for supercomputers, I have a unique perspective on analyzing and solving problems from both a macro and micro level. I thrive on challenges and am constantly seeking new ways to push the boundaries of what's possible."
                    />
                    <SectionText
                        text="I am highly motivated and deeply committed to making a meaningful difference in the world. My greatest strength is developing and executing strategic plans that deliver quantifiable results for my clients."
                    />
                    <SectionText
                        text=" When I'm not busy winning with my clients, you can find me honing my Brazilian jiu-jitsu skills, exploring new investment opportunities, or hitting the links for a round of golf."
                    />
                </FlexColumn>
            </Box>
        </SectionContainer>
    );
};

export default AboutMeWidget;
