import { Box, Typography } from "@mui/material";

const VideoWidget = () => {
    return (
        <Box
            width="100%"
            height="95vh"
            position="relative"
            textAlign="center"
        >
            <video
                src="../assets/new.mp4"
                autoPlay
                loop
                muted
                playsInline
                width="100%"
                height="100%"
            />
            <Box
                width="100%"
                height="100%"
                top="0"
                position="absolute"
                display="flex"
                justifyContent="center"
                alignItems="center"
                backgroundColor='rgba(0,0,0,.4)'
            >
                <Typography
                    fontWeight="bold"
                    variant="h1"
                    color="primary"
                    letterSpacing="0.15rem"
                >
                    Working together with clients to sail to new horizons
                </Typography>
            </Box>
        </Box>
    );
};

export default VideoWidget;
