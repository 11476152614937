import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

const AboutWidget = () => {
    const { palette } = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

    return (
        <Box
            width="100%"
            position="relative"
            display={isNonMobileScreens ? "flex" : "block"}
            flexDirection="column"
            backgroundColor={palette.background.default}
        >
            <Box
                width="100%"
                height="35vh"
                sx={{
                    backgroundImage: `url(${"../assets/aboutHeader.jpg"})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }}
            ></Box>
            <Box
                width="100%"
                height="100%"
                top="0"
                position="absolute"
                display="flex"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                backgroundColor='rgba(0,0,0,.4)'
            >
                <Typography
                    fontWeight="bold"
                    variant="h1"
                    color="primary"
                    letterSpacing="0.35rem"
                >
                    ABOUT ME
                </Typography>
            </Box>
        </Box>
    );
};

export default AboutWidget;
