import {
    NavigationSharp,
    CopyrightSharp
} from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";

const FooterWidget = () => {
    const { palette } = useTheme();

    return (
        <Box
            width="100%"
            padding="1rem 6%"
            display="flex"
            flexDirection="column"
            backgroundColor={palette.neutral.main}
            gap="0.5rem"
        >
            <FlexBetween gap="1rem" >
                <Typography variant="name" fontSize="clamp(1rem, 1.75rem, 2rem)" color={palette.background.default}>
                    Designed & Developed by Sergio Gomez Jr
                </Typography>
                <FlexBetween gap="1.5rem">
                    <Box
                        sx={{
                            "&:hover": {
                                backgroundColor: palette.primary.dark,
                                cursor: "pointer"
                            }
                        }}
                        onClick={() => window.open("https://www.linkedin.com/in/gomezsergio19/")}
                    >
                        <img src="../assets/linkedin.png" alt="linkedin" />
                    </Box>
                    <Box
                        onClick={() => window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                        })}
                        sx={{
                            color: palette.background.default,
                            "&:hover": {
                                color: palette.primary.dark,
                                cursor: "pointer"
                            }
                        }}
                    >
                        <NavigationSharp />
                    </Box>
                </FlexBetween>
            </FlexBetween>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography color={palette.background.default} >
                    Sergio Gomez Jr.
                </Typography>
                <CopyrightSharp sx={{ color: palette.background.default }} />
                <Typography color={palette.background.default} >
                    | All rights reservered
                </Typography>
            </Box>
        </Box>
    );
};

export default FooterWidget;
