import { Box } from "@mui/material";

/**
 * Section Image Component
 * @param {string} flexBasisValue - Width of the section
 * @param {string} heightValue - Height of the section
 * @param {string} imgWidth - Width of the image
 * @param {string} imgAlt - Description of the image
 * @param {string} imgSrc - Source of the image
 */
const SectionImage = ({ flexBasisValue, heightValue, imgWidth, imgAlt, imgSrc }) => {
    return (
        <Box
            textAlign="center"
            padding="1.5rem 0"
            flexBasis={flexBasisValue}
            height={heightValue}
        >
            <img
                width={imgWidth}
                height="100%"
                style={{
                    objectFit: "cover",
                    borderRadius: "5%",
                    boxShadow: "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"
                }}
                alt={imgAlt}
                src={imgSrc}
            />
        </Box>
    );
}

export default SectionImage;
